// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import './App.css'

function App() {
  return (
    <Router>
      <div className='App'>
        
        <nav>
          <ul>
            <li>
              <Link to="/">主页</Link>
            </li>
            <li>
              <Link to="/projects">诗集</Link>
            </li>
            <li>
              <Link to="/about">关于我</Link>
            </li>
            <li>
              <Link to="/contact">联系信息</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          {/* ...其他路由... */}
        </Routes>
        
        <footer>
          <p>&copy; 2024 MOMO. 版权所有.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;