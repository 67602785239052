import React from 'react';
import './Projects.css'

function Projects() {
  return (
    <div class="container">
        <div class="novel">
            <h2>罡风劲草</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.09.23</p>
            <div class="content">
                <p>君子失意亦使众星捧月，</p>
                <p>小人得势仍是千夫所指。</p>    
                <p>浊浪庸言极擅玩弄是非，</p>    
                <p>清风明月自会诉说清廉！</p>    
            </div>
        </div>
        <div class="novel">
            <h2>无题</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.09.30</p>
            <div class="content">
                <p>秋风拂过百花辞，小院深径香纷飞。</p>
                <p>井中月现铜镜明，举杯同庆人还在。</p>    
                <p>相见难，两行清泪挂双颊，此时月圆别时残。</p>    
            </div>
        </div>
        <div class="novel">
            <h2>相思无言</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.09.30</p>
            <div class="content">
                <p>秋风拂过百花辞，小院深径香纷飞。</p>
                <p>井中月现铜镜明，举杯同庆人还在。</p>    
                <p>相见难，两行清泪挂双颊，此时月圆别时残。</p>   
                <p>欲掷万金买笑颜，幡然醒悟，幡然顿悟！</p>    
                <p>难及花下心头人，更难重拥赤子心。</p>     
            </div>
        </div>
        <div class="novel">
            <h2>相思难-其一</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.10.04</p>
            <div class="content">
                <p>一轮新月照朱阁，万里婵娟难相同。</p>
                <p>山青月明大好时，佳人才子独览月。</p>    
                <p>一纸御令自天来，两心相惜隔千山。</p>    
                <p>所望无处不狼烟，天朝明镜竟难全。</p> 
                <p>有情终有重逢时，明镜无痕亦有时。</p> 
            </div>
        </div>
        <div class="novel">
            <h2>人无皆失时</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.10.11</p>
            <div class="content">
                <p>风何景明大好时,吾心阴霾甚难平。</p>
                <p>微浮枝梢黄金叶,满城尽是黄金甲。</p>    
                <p>蝶飞花繁迷人眼,竟无一朵入吾心。</p>    
                <p>生逢盛世又何干!</p> 
                <p>身在喧嚣忙闹处,孜然一身心寂然。</p> 
                <p>千秋万代又何妨,仍是孤身处闹市。</p> 
                <p>何地是我容身处，又怎知吾的志时。</p>
            </div>
        </div>
        <div class="novel">
            <h2>畅</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.10.14</p>
            <div class="content">
                <p>与君相识共星月，提杯向天邀清秋。</p>
                <p>欲于诸君享酣畅，酒樽无物又何妨？</p>    
            </div>
        </div>
        <div class="novel">
            <h2>前路</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2023.11.11</p>
            <div class="content">
                <p>年华似水月如梭，风雨蹉跎万万年。</p>
                <p>长衫一骑驶荒山，寒风一凛如心去。</p>    
                <p>欲摆清酒宴吾客回首无言独已身。</p>
                <p>仰颈顿月明不再，好庇吾心免风寒。</p>  
                <p>大道前路难寻客，弦月小径独一骑。</p>  
            </div>
        </div>
        <div class="novel">
            <h2>星梦</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2024.01.13</p>
            <div class="content">
                <p>倩倩斜月悬云上，点点星光坠其间。</p>
                <p>月明星稀长相时，三千星宿宿人间。</p>    
                <p>沪渎杉尖莺栖时，满江清梦忆浮生。</p>
                <p>满堂花醉三千客，一剑霜寒十四州。</p>  
            </div>
        </div>
        <div class="novel">
            <h2>晨阳</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2024.02.04</p>
            <div class="content">
                <p>清风掠起九重云，禽影疾掠水中影。</p>
                <p>李树落花吹飞雪，零动飞酒诸方水。</p>    
                <p>晨曦刺使白云去，与之共枕花入梦。</p>
                <p>阳撒天地十四州，不知蚍蜉何为愁。</p>  
            </div>
        </div>
        <div class="novel">
            <h2>鸟啼树下</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2024.05.16</p>
            <div class="content">
                <p>斜日透梢，分陆为界，</p>
                <p>半分金波坠苍枝，一味乌绿遥相望。</p>    
                <p>风吹叶，破碎声，平坐依稀闻鸟啼。</p>    
            </div>
        </div>
        <div class="novel">
            <h2>海霞</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2024.05.20</p>
            <div class="content">
                <p>日落海际，空留霞间独自红。</p>
                <p>弦音一起万事平，却余人世独惆怅。</p>    
            </div>
        </div>
        <div class="novel">
            <h2>随行小记</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2024.06.28</p>
            <div class="content">
                <p>山峦若沙丘一壑，环海共长天一色。</p>
                <p>环山小湖因山启，崖畔小城依山立。</p>    
                <p>而虽立于群山下，心腾飞于云间霞。</p>
                <p>会当临于虹端上，独享盘云独揽月。</p>    
            </div>
        </div>
        <div class="novel">
            <h2>嬉溪间</h2>
            <p><strong>作者:</strong> 翟子墨</p>
            <p><strong>简介:</strong> 有感而发</p>
            <p><strong>发布日期:</strong> 2024.08.23</p>
            <div class="content">
                <p>风静愈使禅意弄，灯下溪浅戏鳞游。</p>
                <p>月磐星上已见缺，无波水中自然圆。</p>    
                <p>斜倚桑槐目微合、心步九霄访婵娟。</p>
            </div>
        </div>
    </div>
  );
}

export default Projects;