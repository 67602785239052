// src/components/About.js
import React from 'react';

function About() {
  return (
    <section id="about">
      <h1>个人简介</h1>
      <p>吟游诗人赊月，也正是翟子墨 Jerry，若有知音，愿共勉。</p>
    </section>
  );
}

export default About;