// src/components/Contact.js
import React from 'react';

function Contact() {
  return (
    <section id="contact">
      <h1>联系方式</h1>
      <p>电子邮件: <a href="jerry.zhaizimo@gmail.com">jerry.zhaizimo@gmail.com</a></p>
      <p>电话号码: 137 6409 2309</p>
      {/* <p>社交媒体: 
        <a href="https://twitter.com/yourprofile">Twitter</a> | 
        <a href="https://linkedin.com/in/yourprofile">LinkedIn</a>
      </p> */}
    </section>
  );
}

export default Contact;