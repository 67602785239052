// src/components/Home.js
import React from 'react';

function Home() {
  return (
    <div className='home'>
      <h1>欢迎来到我的个人网站</h1>
      <p>序</p>
      <p>语言，承载语者的情感，</p>
      <p>时而苍劲，时而略显无力，</p>
      <p>可一针见血，承载情绪，亦可倘如清风拂过，带走那些不知何处升起的情愫，送往他该去向的远方...</p>
      <p></p>
    </div>
  );
}



export default Home;